<template>
  <section class="error-section">
    <img src="@/static/black-logo.svg" alt="">
    <div class="error-content">
      <img src="@/static/mirage-pale.png" alt="">
      <h1 class="mb-2">
        Page Not Found
      </h1>
      <div class="mb-5">
        We can’t seem to find the page you’re looking for
      </div>
      <primary-button
        :href="landingPath"
      >
        Go to Homepage
      </primary-button>
    </div>
    <div class="about-dev">
      Powered by <a href="https://redentu.com">Redentu</a>
    </div>
  </section>
</template>

<script>
import PrimaryButton from '@/components/ui/Buttons/PrimaryButton';

export default {
  name: 'Error404',
  components: { PrimaryButton },
  computed: {
    landingPath() {
      return `${process.env.VUE_APP_LANDING_URL}`;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  .error-section {
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 40px;
  }
  .error-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
  }
  .about-dev {
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);

    a {
      text-decoration: unset;
    }
  }
</style>
