<template>
  <div class="error-403">
    <svg id="robot-error" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 118.9">
      <defs>
        <clipPath id="white-clip">
          <circle
            id="white-eye"
            fill="#cacaca"
            cx="130"
            cy="65"
            r="20"
          />
        </clipPath>
        <text id="text-s" class="error-text" y="106"> 403 </text>
      </defs>
      <path
        class="alarm"
        fill="#e62326"
        d="M120.9 19.6V9.1c0-5 4.1-9.1 9.1-9.1h0c5 0 9.1 4.1 9.1 9.1v10.6"
      />
      <use
        xlink:href="#text-s"
        x="-0.5px"
        y="-1px"
        fill="black"
      />
      <use xlink:href="#text-s" fill="#2b2b2b" />
      <g id="robot">
        <g id="eye-wrap">
          <use xlink:href="#white-eye" />
          <circle
            id="eyef"
            class="eye"
            clip-path="url(#white-clip)"
            fill="#000"
            stroke="#7a6fdb"
            stroke-width="2"
            stroke-miterlimit="10"
            cx="130"
            cy="65"
            r="11"
          />
          <ellipse
            id="white-eye"
            fill="#2b2b2b"
            cx="130"
            cy="40"
            rx="18"
            ry="12"
          />
        </g>
        <circle
          id="tornillo"
          class="lightblue"
          cx="105"
          cy="32"
          r="2.5"
        />
        <use xlink:href="#tornillo" x="50" />
        <use xlink:href="#tornillo" x="50" y="60" />
        <use xlink:href="#tornillo" y="60" />
      </g>
    </svg>
    <h1>You are not allowed to enter here, the content is not available for you!</h1>
    <h2>Go <a :href="landingPath">Home!</a></h2>
  </div>
</template>
<script>
export default {
  name: 'Error403',
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    landingPath() {
      return `${process.env.VUE_APP_LANDING_URL}`;
    },
  },
  mounted() {
    const root = document.documentElement;
    const eyef = document.getElementById('eyef');
    let cx = document.getElementById('eyef').getAttribute('cx');
    let cy = document.getElementById('eyef').getAttribute('cy');

    document.addEventListener('mousemove', (evt) => {
      // eslint-disable-next-line no-restricted-globals
      const x = evt.clientX / innerWidth;
      // eslint-disable-next-line no-restricted-globals
      const y = evt.clientY / innerHeight;

      root.style.setProperty('--mouse-x', x);
      root.style.setProperty('--mouse-y', y);

      cx = 115 + 30 * x;
      cy = 50 + 30 * y;
      eyef.setAttribute('cx', cx);
      eyef.setAttribute('cy', cy);
    });

    document.addEventListener('touchmove', (touchHandler) => {
      // eslint-disable-next-line no-restricted-globals
      const x = touchHandler.touches[0].clientX / innerWidth;
      // eslint-disable-next-line no-restricted-globals
      const y = touchHandler.touches[0].clientY / innerHeight;

      root.style.setProperty('--mouse-x', x);
      root.style.setProperty('--mouse-y', y);
    });
  },
};
</script>
<style lang="scss" scoped>
  @import "@/sass/_variables.scss";
  .error-403 {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    height: 100vh;
    background: #1b1b1b;
    color: white;
    font-family: "Bungee", cursive;
  }
  .error-text {
    font-weight: 500;
    font-size: 24px;
  }

  body {
    background: #1b1b1b !important;
    color: white !important;
    font-family: "Bungee", cursive;
    margin-top: 50px;
    text-align: center;
  }
  a {
    color: $primary;
    text-decoration: none;
  }
  a:hover {
    color: white;
  }
  svg {
    width: 50vw;
  }
  .lightblue {
    fill: #444;
  }
  .eye {
    cx: calc(115px + 30px * var(--mouse-x));
    cy: calc(50px + 30px * var(--mouse-y));
  }
  #eye-wrap {
    overflow: hidden;
  }
  .error-text {
    font-size: 120px;
  }
  .alarm {
    animation: alarmOn 0.5s infinite;
  }

  @keyframes alarmOn {
    from {
      fill: $warning;
    }
    to {
      fill: $primary;
    }
  }

</style>
